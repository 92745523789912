.container {
  display: grid;
  grid-template-columns: 1fr 2fr;

  .formContainer {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5rem;
    border-radius: 0.4rem;
    background-color: #fcfcfc;
    gap: 2rem;

    .logo {
      width: 25rem;
      height: 6rem;
      object-fit: contain;
      margin: 0 auto;
    }

    .title {
      font-size: 2.5rem;
      text-align: center;
      margin-bottom: 2rem;

      .companyName {
        color: #ea5607;
      }
    }

    .inputContainer {
      font-size: 1.4rem;

      & > label {
        margin-bottom: 0.5rem;
      }
    }

    .buttonContainer {
      display: grid;
    }
  }

  .imageContainer {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
  }
}

.button {
  height: 4.5rem;
}
