.mainLayout {
  height: 100vh;
}

.contentLayout {
  background: #fff;

  &__content {
    margin: 2.4rem 1.6rem 0;
    overflow: auto;
    padding: 2.4rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    background-color: transparent !important;
  }
}
