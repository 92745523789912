.label {
  margin-bottom: 0.5rem;
}

.row {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.minusIcon {
  color: #ce0f0f;
  cursor: pointer;
}
