//utils
@import '../styles/utilities/reboot';
@import '../styles/utilities/grid';
@import '../styles/utilities/typography';
@import '../styles/utilities/toastify';
@import '../styles/utilities/slider';
@import '../styles/utilities/other';

//theme
@import '../styles/theme/base';
@import '../styles/theme/light';
@import '../styles/theme/dark';

.ant-upload-list.ant-upload-list-picture-card {
  display: flex;
  align-items: center;
  .ant-upload.ant-upload-select {
    height: 4rem !important;
    align-self: center;
    margin: unset !important;
    margin-inline-end: unset !important;
    display: inline-block;
  }
}

* {
  &::selection {
    color: white;
    background: rgba(214, 35, 43, 0.5);
  }
}

.ant-picker.css-17h9a2n,
.ant-picker.css-dev-only-do-not-override-17h9a2n {
  width: 100% !important;
}
