.container {
  padding: 1rem 2rem;
  background: var(--color-black);
  border-radius: 0.4rem;
  color: var(--color-white);

  &.noLabel {
    padding: 0.5rem 1rem;
  }

  &.disabled {
    opacity: 0.5;
  }
}
