//html {
//  font-size: 50%;
//}

.wrapper {
  background: white;
  color: #444a53;
  width: 99.5rem;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;

  .companyInfoContainer {
    display: grid;
    grid-template-columns: 3.5fr 1.5fr;
    align-items: flex-start;

    .logoDateContainer {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .dateInfoContainer {
        gap: 1rem;
        .dateInfo {
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          gap: 1rem;

          .dateInfoLabel {
            font-weight: 700;
          }
        }
      }
    }

    .addressContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .companyName {
        display: flex;
        align-items: flex-end;
        column-gap: 0.5rem;
        .big {
          font-weight: 700;
          font-size: 1.6rem;
        }

        .small {
          font-weight: 600;
          font-size: 1.2rem;
        }
      }
    }
  }

  .customerInfoContainer {
    font-size: 1.2rem;
    .customerName {
      font-weight: 600;
    }
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border: 0.1rem solid rgba(38, 60, 143, 0.5);
    border-radius: 0.4rem;
    gap: 2rem;

    &.onlyPrint {
      display: none;
    }

    &.beforePageBreak {
      page-break-before: always;
    }

    &.filledForm {
      background-color: rgba(38, 60, 143, 0.15);

      .formItem {
        .bestOfferContainer {
          background: white;
          border: 0.1rem solid rgba(38, 60, 143, 0.5);
        }
      }
    }

    .formItem {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .title {
        color: #263c8f;
        font-weight: 600;
        font-size: 1.4rem;
      }

      .bestOfferContainer {
        display: grid;
        grid-template-columns: 1.5fr repeat(2, 1fr);
        align-items: center;
        gap: 2rem;
        border: 0.2rem solid #d9d9d9;
        border-radius: 0.4rem;
        font-size: 1.4rem;

        .companyInfo {
          display: flex;
          align-items: center;
          gap: 1rem;

          .companyLogo {
            width: 11rem;
            height: 5rem;
            object-fit: contain;
            padding: 0.5rem;
          }

          .packageInfo {
            .companyName {
              font-weight: 600;
            }

            .packageName {
              font-size: 1.2rem;
            }
          }
        }
      }

      .additionalInfoContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 2rem;
        align-items: center;
      }

      .additionalInfo {
        display: flex;
        align-items: center;
        gap: 1rem;

        .info {
          font-size: 1.4rem;

          .label {
            font-weight: 700;
          }

          .installmentDue {
            font-size: 1.4rem;
          }
        }
      }

      .companyItemContainer {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .companyItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 2rem;

          .companyInfo {
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            font-weight: 600;
            border: 0.2rem solid #d9d9d9;
            border-radius: 0.4rem;
            width: 50%;
            gap: 1rem;

            .companyLogo {
              width: 12rem;
              height: 3.2rem;
              object-fit: contain;
            }

            .companyName {
              white-space: nowrap;
              flex-grow: 1;
            }

            .packageName {
              flex-grow: 0.7;
              font-weight: normal;
              text-align: right;
              padding-right: 1rem;
            }
          }

          .cashPrice,
          .installmentPrice {
            width: 25%;
          }
        }
      }
    }
  }

  .generalDescriptionContainer {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    break-inside: avoid;

    .title {
      font-weight: 700;
      font-size: 1.2rem;
    }
    p {
      font-size: 1.2rem;
    }
    .authorizedInfoContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .title {
        font-size: 1.4rem;
        font-weight: 600;
      }

      .authorizedFullName {
        font-size: 1.6rem;
        font-weight: 700;
      }
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 0.2rem solid #bec4dd;
  padding: 1rem 3rem;
  background: white;
  font-size: 1.2rem;
  break-inside: avoid;

  .description {
    text-align: center;
  }

  .footerLogoContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: #263c8f;
    font-weight: 600;
  }
}

.infoContainer {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  column-gap: 1rem;

  .infoLabel {
    font-weight: 700;
  }

  .info {
    &::before {
      content: attr(data-label) ': ';
      font-weight: 700;
    }
  }
}

.checkIcon {
  color: #99c455;
  font-size: 1.8rem;
}

@media print {
  @page {
    //margin: 1rem 0;
    margin: 0;
    size: A4;
  }

  @page :first {
    //margin: 0 0 0.39in 0;
    margin-top: 0;
  }

  button {
    display: none;
  }

  .container {
    -webkit-print-color-adjust: exact !important;
    .companyInfoContainer {
      grid-template-columns: 3.5fr 1.5fr;
    }
    .generalDescriptionContainer {
      padding-top: 1rem;
    }

    .formContainer {
      &.onlyPrint {
        display: block;
      }

      &.hidePrint {
        display: none;
      }
    }
  }
}
