.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  gap: 0.3rem;
  flex-grow: 1;

  .inputPrefix {
    font-weight: 600;
  }
}

.notFoundContainer {
  padding: 1rem;
  border: 0.1rem solid #d9d9d9;
  border-radius: 0.4rem;
  font-size: 1.2rem;
  text-align: center;
  color: #9d9d9d;
}

.inputContainer {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  gap: 1rem;
}

.addButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 500ms;

  &:hover {
    opacity: 1;
  }

  .addIcon {
    color: #119813;
  }

  .addButtonText {
    font-size: 1.2rem;
  }
}

.addFormButtonContainer {
  justify-content: flex-end;
}

.label {
  margin-bottom: 0.5rem;
}

.minusIcon {
  text-align: center;
  color: #ce0f0f;
  cursor: pointer;
}

.centerText {
  text-align: center;
}
