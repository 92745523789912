.textCenter {
  text-align: center;
}

.inputPrefix {
  color: #c9c9c9;
}
.label {
  margin-bottom: 0.5rem;
}
.required {
  color: #d6232b;
}

.offerTypeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionContainer {
  padding-top: 1rem;
}

.inputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.row {
  display: flex;
  align-items: center;
}

.center {
  justify-content: center;
}

.spaceBetween {
  justify-content: space-between;
}

.additionalInfo {
  gap: 1rem;
  border: 0.1rem solid #c3c7d3;
  padding: 1rem;

  .additionalInfoLabel {
    font-weight: 600;
  }

  .minusIcon {
    color: #ce0f0f;
    cursor: pointer;
  }
}

.logo {
  width: auto;
  height: 4rem;
  object-fit: contain;
}

.fileTypes {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.customerList {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 2rem 0;
  .customer {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    cursor: pointer;
    padding: 1rem 2rem;
    border-radius: 0.4rem;
    transition: all 500ms;
    font-weight: 500;

    .info {
      &:not(:first-child) {
        &::before {
          content: '-';
          margin-right: 1rem;
        }
      }
    }

    &:hover {
      background-color: #fff1f0;
    }
  }
}

.dateContainer {
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  background-color: #f9f9f9;
  border: 0.1rem solid #eeeeee;
  border-radius: 0.4rem;

  .labelContainer {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;

    .label {
      flex-grow: 1;

      &:nth-child(2) {
        margin-left: 3.5rem;
      }
    }
  }
}

.customerSelectorBtn {
  display: flex;
  justify-content: flex-end;
}

.customerInfo {
  &__label {
    font-weight: 600;
  }

  &__value {
    font-size: 1.4rem;
    font-weight: normal;
  }
}
