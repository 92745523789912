.buttonContainer{ 
    display: flex; 
    align-items: flex-end; 
    gap: 10px; 
    padding: 10px 0px; 
    flex-wrap: wrap;

    .input {
        width: 200px;
    }
}