.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
  border-radius: 0.4rem;

  &:not(:disabled) {
    &:hover {
      color: var(--color-white);
      border-color: unset;
      transform: scale(1.005);
    }

    &.primary {
      color: var(--color-white);
      background: var(--color-primary);

      &.outlined {
        border: 0.1rem solid var(--color-primary);
        background: transparent;
        color: var(--color-primary);
        transition: all 250ms;

        &:hover {
          background: var(--color-primary);
          color: var(--color-white);
        }
      }
    }

    &.secondary {
      color: var(--color-white);
      background: var(--color-black);
      border: 0.1rem solid var(--color-black);

      &.outlined {
        background: transparent;
        color: var(--color-black);

        &:hover {
          background: var(--color-black);
          color: var(--color-white);
        }
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      transform: unset;
    }
  }
  &.loading {
    &:hover {
      transform: unset;
    }
  }
}
