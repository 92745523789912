// .swiper-pagination-bullet {
//   width: 1rem;
//   height: 1rem;
//   text-align: center;
//   line-height: 20px;
//   font-size: 0;
//   background: rgba(234, 87, 7, 15);
// }

// .swiper-pagination-bullet-active {
//   background: #fe5000;
// }

// .swiper-button-disabled {
//   cursor: no-drop !important;
//   opacity: 0.3 !important;
// }

// Banner Swiper
.bannerSwiperContainer .swiper-pagination-bullet {
  width: 1.5rem;
  height: 0.5rem;
  text-align: center;
  line-height: 20px;
  border-radius: 0.15rem;
  font-size: 0;
  background: rgba(234, 87, 7, 15);
}
.bannerSwiperContainer .swiper-pagination-bullet-active {
  width: 3rem;
  background: #fe5000;
}
.bannerSwiperContainer .swiper-button-disabled {
  cursor: no-drop !important;
  opacity: 0.3 !important;
}
