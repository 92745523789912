.container {
  border: 0.2rem solid #d9d9d9;
  border-radius: 0.4rem;
  position: relative;
  width: 100%;
  transition: all 200ms;

  &.editable {
    &:hover {
      border-color: var(--color-primary);
    }
  }

  &.displayText {
    height: unset;
    border-radius: unset;
    border: unset;
    width: unset;

    &:hover {
      border-color: unset;
    }

    .input {
      padding: unset;
      font-size: 1.4rem;
      background: transparent;
    }
  }

  .label {
    position: absolute;
    left: 1rem;
    background: white;
    padding: 0 1rem;
    font-weight: 600;
    z-index: 2;
  }

  .input {
    position: relative;
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    border-radius: 0.4rem;
    padding: 1rem 2rem;
    color: #444a53;
    background: white;
    font-weight: 400;
  }
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
}
