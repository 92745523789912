[data-theme='dark'] {
  --color-primary: #ea5607;
  --color-light-orange: #ffd7c4;
  --color-soft-o: #ffc4a5;
  --color-black: #ffffff;
  --color-dark-gray: #c3c7d3;
  --color-dark-blue: #767d8d;
  --color-dark-blue-o: #babbc0;
  --color-gray: #b1b0af;
  --color-soft-gray: #e3e2e2;
  --color-v-blue-gray: #1c1f28;
  --color-v-gray: #171721;
  --color-orangray: #1a1a29;
  --color-blue-gray: #212531;
  --color-white: #0e1016;

  //toast
  --toastify-color-dark: var(--color-white);
  --toastify-text-color-dark: var(--color-black);
  --toastify-toast-min-height: 5rem;
  --toastify-color-info: var(--color-info);
  --toastify-color-warning: var(--color-warning);
  --toastify-color-success: var(--color-success);
  --toastify-color-error: var(--color-danger);

  //exchange
  --color-green: #03a66d;
  --color-red: #c90950;
  --color-green-op: #03a66d;
  --color-red-op: #c90950;
  --color-mode-red: #f84960;

  //alert
  --color-warning: #ffc12c;
  --color-warning-bg: #1e1c11;
  --color-error-bg: #311d1d;
  --color-warning-border: #423806;
  --color-error-border: #420606;
  --color-info: #17a2b8;
  --bw-color-success: #28a745;
  --bw-color-soft-success: #9ae6ab;
  --bw-color-danger: #dc3545;

  //theme(alpha)
  --bw-color-body: #070e11;
  --bw-color-main: #070e11;
  --bw-color-header: #131c21;
  --bw-color-navigation: #131c21;
  --bw-color-footer: #070e11;
  --bw-color-card-hover: #fff;
  --bw-color-section: #131c21;
  --bw-color-card: #131c21;
  --bw-color-light-button: #131c21;
  --bw-color-input: #000000;
  --bw-color-border: #222634;
  --bw-color-convert-card: #131c21;
  --bw-color-explore1: #0f161a;
  --bw-color-explore2: #070e11;
  --bw-color-information: #121a1e;
  --bw-color-modal-item-hover: #000000;
  --bw-color-headline: #070e11;
  --bw-color-selection: #523a2d;
  --bw-color-trading: #070e11;
  --bw-box-shadow: 0;
  --bw-color-header-hover: #000000;
  --bw-color-light-gray: #172228;
  --bw-light-hover: #e4dfdf34;

  //depthMobileChart
  --bw-depth-background-color: #131722;
  --bw-depth-plot-background-color: #171b26;
}
