.btnContainer {
  display: flex;
  justify-content: flex-end;
}

.downloadBtn {
  text-decoration: none;
  border-radius: 0.4rem;
  color: var(--color-white);
  background: var(--color-primary);
  padding: 0.5rem 2rem;

  &:hover {
    color: var(--color-white);
    transform: scale(1.005);
  }
}

.tableContainer {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  row-gap: 2rem;

  .card {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    background-color: #fafafa;
    border-radius: 0.4rem;
    padding: 2rem;

    .searchContainer {
      display: flex;
      align-items: center;
      column-gap: 2rem;
    }

    .filterContainer {
      display: flex;
      align-items: flex-end;
      column-gap: 2rem;

      .filterItem {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        flex-grow: 1;
      }
    }

    .clearFilter {
      display: flex;
      justify-content: flex-end;
    }
  }

  .addFormContainer {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
}

.modalBodyContainer {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}

.centerRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  color: #000;

  &:hover {
    color: unset;
  }
}
