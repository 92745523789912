:root {
  --color-primary: #d6232b;
  --color-light-orange: #ffd7c4;
  --color-soft-o: #ffc4a5;
  --color-black: #000000;
  --color-dark-gray: #717171;
  --color-dark-blue: #767d8d;
  --color-dark-blue-o: #babbc0;
  --color-gray: #b1b0af;
  --color-soft-gray: #e3e2e2;
  --color-v-blue-gray: #ebebf0;
  --color-v-gray: #f5f5f5;
  --color-orangray: #f8f5f4;
  --color-blue-gray: #f8f9fb;
  --color-white: #ffffff;

  //toast
  --toastify-color-dark: var(--color-white);
  --toastify-text-color-dark: var(--color-black);
  --toastify-toast-min-height: 5rem;
  --toastify-color-info: var(--color-info);
  --toastify-color-warning: var(--color-warning);
  --toastify-color-success: var(--color-success);
  --toastify-color-error: var(--color-danger);

  //exchange
  --color-green: #03a66d;
  --color-red: #c90950;
  --color-green-op: #03a66d;
  --color-red-op: #c90950;
  --color-mode-red: #f84960;

  //alert
  --color-warning: #9d7108;
  --color-warning-bg: #fefae5;
  --color-error-bg: #f8d0d0;
  --color-warning-border: #fdf1b9;
  --color-error-border: #fdb9b9;
  --color-info: #17a2b8;
  --color-success: #28a745;
  --bw-color-soft-success: #9ae6ab;
  --bw-color-danger: #dc3545;

  //theme(alpha)
  --bw-color-body: #ffffff;
  --bw-color-main: #f9f9f9;
  --bw-color-header: #ffffff;
  --bw-color-navigation: #ffffff;
  --bw-color-footer: #ffffff;
  --bw-color-card-hover: #ffffff;
  --bw-color-section: #131c21;
  --bw-color-card: #ffffff;
  --bw-color-light-button: #ebebf0;
  --bw-color-input: #ededed;
  --bw-color-border: #dfdfdf;
  --bw-color-convert-card: #ebebf0;
  --bw-color-explore1: #fafafa;
  --bw-color-explore2: #ffffff;
  --bw-color-information: #0d1117;
  --bw-color-modal-item-hover: #e1e1e1;
  --bw-color-headline: #131c21;
  --bw-color-selection: #ea56071f;
  --bw-color-trading: #131c21;
  --bw-box-shadow: 0;
  --bw-color-header-hover: #000000;
  --bw-color-light-gray: #f7f7f7;
  --bw-light-hover: #e4dfdf34;

  //depthMobileChart
  --bw-depth-background-color: #fff;
  --bw-depth-plot-background-color: #fff;
}
