:root {
  --bw-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;

  //font sizes
  --fs-8: 0.8rem;
  --fs-10: 1rem;
  --fs-11: 1.1rem;
  --fs-12: 1.2rem;
  --fs-13: 1.3rem;
  --fs-14: 1.4rem;
  --fs-15: 1.5rem;
  --fs-16: 1.6rem;
  --fs-18: 1.8rem;
  --fs-20: 2rem;
  --fs-25: 2.5rem;
  --fs-30: 3rem;
  --fs-31: 3.1rem;
  --fs-39: 3.9rem;
  --fs-48: 4.8rem;
  --fs-50: 5rem;
  --fs-58: 5.8rem;
  --fs-61: 6.1rem;

  //font weights
  --bolder-weight: 800;
  --bold-weight: 700;
  --medium-weight: 500;
  --normal-weight: 400;
  --light-weight: 300;
}
