.actionsContainer {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.uploadCard {
  width: unset !important;
}

.grid {
  flex-grow: 1;
}

.companyName {
  width: calc(100% - 285px);
}

.addCompanyContainer {
  width: 100%; 
  display: flex; 
  justify-content: flex-end; 
  padding: 25px 10px;
}