$border: 0.15rem solid rgba(38, 60, 143, 0.5);
$darkBorder: 0.15rem solid #263c8f;

@mixin backgroundColorSet($opacity) {
  background-color: rgba(38, 60, 143, $opacity);
}

.wrapper {
  background: white;
  color: #444a53;
  width: 74.6rem;

  a {
    text-decoration: none;
    color: #263c8f;
    font-weight: 600;
    font-size: 1.4rem;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
  }

  .companyContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .companyLinkContainer {
      font-size: 1.6rem;
      text-align: right;
      font-weight: 700;
      color: #d6232b;
    }
  }

  .datesContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    .dateContainer {
      display: flex;
      align-items: center;
      border: $border;

      &:last-child {
        border-left: unset;
      }
      .tableLabel {
        border-right: $border;
        @include backgroundColorSet(0.1);
      }
    }
  }

  .customerContainer {
    border: $border;
    border-bottom: unset;

    &.borderBottom {
      border-bottom: $border;
    }

    .customerInfoContainer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-top: $border;
      border-bottom: $border;

      &.longText {
        .customerInfoItem {
          .tableLabel {
            padding: 1rem 0.5rem;
          }
        }
      }

      .customerInfoItem {
        display: flex;
        align-items: center;
        flex-grow: 1;
        width: 50%;

        &:not(:nth-child(even)) {
          border-right: $border;
        }

        &:not(:nth-child(n + 3)) {
          border-bottom: $border;
        }

        .tableLabel {
          width: 50%;
          border-right: $border;
          @include backgroundColorSet(0.1);
        }
        .tableValue {
          width: 50%;
          font-size: 1.2rem;

          &.longText {
            font-size: 1rem;
          }
        }
      }

      &.oneLine {
        .customerInfoItem {
          width: 100%;
          border-right: unset;
          &:not(:last-child) {
            border-bottom: $border;
          }
        }
      }
    }
  }

  .bestOfferContainer {
    border: 0.2rem solid #263c8f;
    box-shadow: 10px 10px 5px 0px #263c8faa;

    .header {
      text-align: center;
      font-weight: bold;
      font-size: 1.6rem;
      background: #263c8f;
      color: white;
      padding: 0.5rem;
    }

    .offerItem {
      display: flex;
      align-items: center;
      border-bottom: $darkBorder;

      .tableLabel {
        width: 50%;
        border-right: $darkBorder;
        display: flex;
        align-items: center;
        //height: 5rem;
        height: 4rem;
        @include backgroundColorSet(0.2);
      }
      .tableValue {
        width: 50%;
        text-align: center;
      }

      .bestCompanyInfo {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        img {
          width: 10rem;
          height: 3rem;
          object-fit: contain;
        }
        .companyName {
          font-weight: 600;
          margin-right: 1.5rem;
        }

        .packageName {
          font-size: 1.2rem;
        }
      }
    }

    .offerItems {
      display: flex;
      align-items: center;
      flex-grow: 1;
      .offerItem {
        width: 50%;
        border-bottom: unset;
        border-left: unset;

        &:not(:last-child) {
          border-right: $darkBorder;
        }

        .tableLabel {
          height: unset;
        }

        //&:nth-child(even) {
        //  .tableLabel {
        //    border-left: $darkBorder;
        //  }
        //}
      }
    }

    .offerContent {
      border-top: $darkBorder;
      .tableLabel {
        text-align: center;
      }

      .contentList {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        border-top: $darkBorder;

        .contentItem {
          display: flex;
          align-items: center;
          column-gap: 0.5rem;
          padding: 0.5rem;

          .contentItemInfo {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;

            .contentItemValue {
              font-weight: 300;
            }
          }
        }
      }
    }
  }

  .otherOffers {
    border: $border;
    .header {
      text-align: center;
      padding: 0.5rem;
      @include backgroundColorSet(0.6);
      color: white;
      font-weight: 700;
    }

    .tableHeader {
      display: flex;
      align-items: center;
      flex-grow: 1;
      border-bottom: $border;

      .tableLabel {
        width: 18%;
        flex-grow: 1;
        @include backgroundColorSet(0.1);
        text-align: center;

        &.tableCompanyHeader {
          width: 56%;
        }

        &:not(:first-child) {
          border-left: $border;
        }
      }
    }

    .tableContent {
      .tableContentItem {
        display: flex;
        flex-grow: 1;
        align-items: center;

        &:not(:last-child) {
          .companyInfo,
          .inputContainer {
            border-bottom: $border;
          }
        }

        .companyInfo {
          display: flex;
          align-items: center;
          column-gap: 0.5rem;
          width: 56%;
          //padding-right: 0.5rem;
          padding: 0.5rem;

          .companyLogo {
            width: 10rem;
            height: 2.5rem;
            object-fit: scale-down;
          }

          .companyName {
            flex-grow: 1;
            margin-right: 1.5rem;
          }

          .packageName {
            padding-right: 1rem;
            color: #9f9898;
          }
        }

        .inputContainer {
          border-left: $border;
          padding: 0.75rem 0.5rem;
          width: 18%;
          text-align: center;
        }
      }
    }
  }

  .descriptionText {
    font-size: 1.2rem;
  }

  .tableLabel {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.5rem;
    flex-grow: 1;
    @include backgroundColorSet(0.3);
  }
  .tableValue {
    font-size: 1.2rem;
    padding: 0 0.5rem;
    flex-grow: 1;
  }

  .checkIcon {
    color: #99c455;
    font-size: 1.5rem;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 0.2rem solid #bec4dd;
  padding: 1rem 3rem;
  background: white;
  font-size: 1.2rem;

  .description {
    text-align: center;
  }

  .footerLogoContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: #263c8f;
    font-weight: 600;
  }
}
