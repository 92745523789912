html {
  font-size: 62.5%;
  touch-action: manipulation;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: var(--bw-font);
  background: var(--color-body);
  color: var(--color-black);
}

::selection {
  background: var(--color-selection);
}

// @media (max-width: 1600px) {
// }

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px !important;
  }
}

// @media (max-width: 1200px) {
// }

// @media (max-width: 992px) {
// }

// @media (max-width: 768px) {
// }

// @media (max-width: 576px) {
// }

// @media (max-width: 480px) {
// }

@media (max-width: 369px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 370px) and (max-width: 390px) {
  html {
    font-size: 58%;
  }
}
