.sider {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  padding-top: 2rem;
  min-height: 100%;
  overflow: scroll;

  &__content_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__logo {
    color: #fff;
    text-align: center;
    margin: 3rem 2rem 7rem;

    &-img {
      width: 100% !important;
      height: 5rem !important;
      object-fit: contain;
    }
  }

  &__menu {
    font-size: 1.6rem;

    &-item {
      display: block;
      border-radius: 0.3rem;
      list-style-type: none;
      text-decoration: none;
      cursor: pointer;

      > li {
        color: rgba(255, 255, 255, 0.64) !important;
        padding: 0 2.5rem !important;
        background-color: unset !important;
      }

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      &:hover {
        color: unset;

        > li {
          color: #fff !important;
        }
      }

      &.active {
        > li {
          color: #fff !important;
          background: var(--color-primary) !important;
        }
      }

      &--child-container {
        background-color: #000c17;
        transition: height 2s;
      }

      &--child {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > li {
          padding-left: 5rem !important;
        }
      }
    }
  }

  &__footer {
    width: 100%;
    padding: 2rem;

    &--button {
      width: 100%;
    }

    &-logo {
      width: 100% !important;
      height: 3.5rem !important;
      object-fit: contain;
      margin-bottom: 2rem;

      &-link {
        display: flex;
        justify-content: center;
      }
    }
  }
}
